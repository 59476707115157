





















































import { defineComponent } from '@nuxtjs/composition-api';
import Icon from '../../almarwan/components/Icon.vue';

export default defineComponent({
  name: 'SocialLabel',
  components: {
    Icon
  },
  props: {
    prodName: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      routeName: ''
    }
  }
})
